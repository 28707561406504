<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppEobLoyaltyBanner',

    extends: CmsBaseComponent,

    /* eslint-disable vue/no-unused-properties */
    props: {
        firstText: {
            type: String,
            default: '',
            required: true,
        },

        secondText: {
            type: String,
            default: '',
            required: true,
        },

        buttonText: {
            type: String,
            default: '',
            required: true,
        },

        landingText: {
            type: String,
            default: '',
            required: true,
        },

        backgroundColor: {
            type: String,
            default: '',
            required: true,
        },

        buttonColor: {
            type: String,
            default: '',
            required: true,
        },

        buttonTextColor: {
            type: String,
            default: '',
            required: true,
        },

        textColor: {
            type: String,
            default: '',
            required: true,
        },

        tag: {
            type: String,
            default: '',
        },

        imageUrl: {
            type: String,
            default: '',
        },

        deepLink: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                imageUrl: {
                    isImageRelativeUrl: true,
                },

                buttonText: {
                    max: 50,
                },

                landingText: {
                    max: 50,
                },

                backgroundColor: {
                    isHexColor: true,
                },

                buttonColor: {
                    isHexColor: true,
                },

                buttonTextColor: {
                    isHexColor: true,
                },

                textColor: {
                    isHexColor: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
