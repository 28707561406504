<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppTestNewTypes',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        vimeoUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        imageRelativeUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        url: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                vimeoUrl: {
                    isVimeoUrl: true,
                    required: true,
                },

                imageRelativeUrl: {
                    isImageRelativeUrl: true,
                    required: true,
                },

                url: {
                    isUrl: true,
                    required: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
