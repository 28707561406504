<template>
    <ContainerContent v-if="!disabled" class="marketing-hero-block">
        <div class="hero-media">
            <slot />
        </div>
        <MarketingHeroBlockContent
            :subheader="subheader"
            :header="header"
            :body="body"
            :button-label="buttonLabel"
            :bg-color="bgColor"
            :text-variant="textVariant"
            :body-text-color="bodyTextColor"
            :button-variant="buttonVariant"
            class="hero-content"
        />
        <component :is="componentTag" :path="url" class="link" />
    </ContainerContent>
</template>

<script>
import { checkIfExistsInValuesMap } from '@assets/props';
import { isHttpLink } from '@assets/link';

import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseLink from '@atoms/BaseLink/BaseLink';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import MarketingHeroBlockVideo from '@molecules/MarketingHeroBlockVideo/MarketingHeroBlockVideo';
import MarketingHeroBlockContent from '@molecules/MarketingHeroBlockContent/MarketingHeroBlockContent';

import { BUTTON_LARGE_VARIANTS } from '@eobuwie-ui/components/ButtonLarge/v1';
import { BODY_TEXT_COLORS } from '@eobuwie-ui/components/BodyText/v1';
import { HEADER_TEXT_VARIANTS } from '@eobuwie-ui/components/HeaderText/v1';

export default {
    name: 'MarketingHeroBlock',

    components: {
        BaseLink,
        BaseLinkStatic,
        ContainerContent,
        MarketingHeroBlockVideo,
        MarketingHeroBlockContent,
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        subheader: {
            type: String,
            required: true,
        },

        header: {
            type: String,
            default: '',
        },

        body: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        bgColor: {
            type: String,
            default: '#0000',
        },

        textVariant: {
            type: String,
            default: HEADER_TEXT_VARIANTS.BASIC,
            validator: checkIfExistsInValuesMap(HEADER_TEXT_VARIANTS),
        },

        bodyTextColor: {
            type: String,
            default: BODY_TEXT_COLORS.BASIC,
            validator: checkIfExistsInValuesMap(BODY_TEXT_COLORS),
        },

        buttonVariant: {
            type: String,
            default: BUTTON_LARGE_VARIANTS.SECONDARY,
            validator: checkIfExistsInValuesMap(BUTTON_LARGE_VARIANTS),
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        componentTag() {
            return isHttpLink(this.url) ? BaseLinkStatic : BaseLink;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-hero-block {
    @apply p-ui-0;
    @apply relative;

    .hero-media {
        @apply w-full h-full;
    }

    .link {
        @apply absolute top-0 left-0 w-full h-full;
    }

    @screen lg {
        .hero-content {
            @apply absolute top-ui-percent-50 left-ui-0 transform-ui-translate-y-minus-percent-50;
        }
    }
}
</style>
